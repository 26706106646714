import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Configuration, TenantSetting, TenantSettingsApi } from '../api/tenant';
import { useAuth } from '../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { NotificationContext } from './NotificationContext';
import LoadingSplash from '../components/shared/LoadingSplash';

interface TenantContextProperties {
  tenantId: string;
  defaultHourlyRateIncVAT: number;
  defaultManufacturingPeriodInDays: number;
  VATPercentage: number;
  mandatoryDepositPercentage: number;
  accountingSystem: string;
  tmsSystem: string;
  externalCalendarSystem: string;
  marketingMailSystem: string;
  accounting?: {
    divisionId?: string;
    clientId?: string;
    clientSecret?: string;
    warehouseId?: string;
    exactCustomProductGuId?: string;
  };
  applicationSettings: {
    showInstagramTagPopup: boolean;
    tenantInstagramId: string;
  };

  raw?: Record<string, any>;
}

const TenantContext = createContext<TenantContextProperties | undefined>(
  undefined,
);

const TenantContextProvider = ({ tenantId, children }) => {
  const { auth } = useAuth();
  const notificationContext = useContext(NotificationContext);
  const { replace: historyReplace } = useHistory();

  // context logic
  const defaults: TenantContextProperties = {
    tenantId,
    defaultHourlyRateIncVAT: 85,
    defaultManufacturingPeriodInDays: 49,
    VATPercentage: 21.0,
    mandatoryDepositPercentage: 45.0,
    accountingSystem: 'scope',
    tmsSystem: '',
    externalCalendarSystem: '',
    marketingMailSystem: '',
    accounting: {
      clientId: '',
      clientSecret: '',
      divisionId: '',
      exactCustomProductGuId: '',
      warehouseId: '',
    },
    applicationSettings: {
      showInstagramTagPopup: false,
      tenantInstagramId: '',
    },
  };

  const [settingsApiResponse, setSettingsApiResponse] =
    useState<TenantSetting[]>();

  const apiOptions = useMemo(() => {
    return new Configuration({
      basePath: process.env.REACT_APP_SERVICE_URL_TENANT,
      accessToken: auth?.jwt,
    });
  }, [auth?.jwt]);

  const tenantSettingsApi = useMemo(
    () => (apiOptions ? new TenantSettingsApi(apiOptions) : undefined),
    [apiOptions],
  );

  useLayoutEffect(() => {
    if (auth.isLoggedIn && tenantSettingsApi)
      tenantSettingsApi
        .getSettings()
        .then((settings) => {
          if (settings.status === 200) {
            setSettingsApiResponse(settings.data);
          }
        })
        .catch(() => {});
  }, [tenantSettingsApi, auth?.isLoggedIn]);

  const tenantSettings = useMemo(() => {
    if (settingsApiResponse === undefined) return;

    const data = defaults;

    settingsApiResponse.forEach((v) => {
      if (v.subsystem == 'CORE') {
        switch (v.settingName) {
          case 'HOURLY-RATE':
            data.defaultHourlyRateIncVAT =
              v.settingValue ?? defaults.defaultHourlyRateIncVAT;
            break;
          case 'VAT-PERCENTAGE':
            data.VATPercentage = v.settingValue ?? defaults.VATPercentage;
            break;
          case 'DEPOSIT-PERCENTAGE':
            data.mandatoryDepositPercentage =
              v.settingValue ?? defaults.mandatoryDepositPercentage;
            break;
          case 'MANUFACTURING-PERIOD':
            data.defaultManufacturingPeriodInDays =
              v.settingValue ?? defaults.defaultManufacturingPeriodInDays;
            break;
          case 'ACCOUNTING-SYSTEM':
            data.accountingSystem = v.settingValue ?? defaults.accountingSystem;
            break;
          case 'TMS-SYSTEM':
            data.tmsSystem = v.settingValue ?? defaults.tmsSystem;
            break;
          case 'EXT-CALENDAR':
            data.externalCalendarSystem =
              v.settingValue ?? defaults.externalCalendarSystem;
            break;
          case 'MARKETING-MAIL-SYSTEM':
            data.marketingMailSystem =
              v.settingValue ?? defaults.marketingMailSystem;
            break;
          case 'FLAG-SHOW-INSTAGRAM-POPUP':
            data.applicationSettings.showInstagramTagPopup =
              v.settingValue != undefined
                ? Boolean(v.settingValue)
                : defaults.applicationSettings.showInstagramTagPopup;
            break;
          case 'TENANT-INSTAGRAM-ID':
            data.applicationSettings.tenantInstagramId =
              v.settingValue ?? defaults.applicationSettings.tenantInstagramId;
            break;
        }
      } else if (v.subsystem == 'ACCOUNTING') {
        switch (v.settingName) {
          case 'DIVISION-ID':
            if (!data.accounting) data.accounting = {};
            data.accounting.divisionId =
              v.settingValue ?? defaults.accounting?.divisionId;
            break;
          case 'CLIENT-ID':
            if (!data.accounting) data.accounting = {};
            data.accounting.clientId =
              v.settingValue ?? defaults.accounting?.clientId;
            break;
          case 'CLIENT-SECRET':
            if (!data.accounting) data.accounting = {};
            data.accounting.clientSecret =
              v.settingValue ?? defaults.accounting?.clientSecret;
            break;
          case 'EXACT-CUSTOM-PRODUCT-GUID':
            if (!data.accounting) data.accounting = {};
            data.accounting.exactCustomProductGuId =
              v.settingValue ?? defaults.accounting?.exactCustomProductGuId;
            break;
          case 'WAREHOUSE-ID':
            if (!data.accounting) data.accounting = {};
            data.accounting.warehouseId =
              v.settingValue ?? defaults.accounting?.warehouseId;
            break;
        }
      }
    });

    let raw = settingsApiResponse.reduce(
      (obj, item) => ({
        ...obj,
        [`${item.subsystem.toUpperCase()}:${item.settingName.toUpperCase()}`]:
          item.settingValue,
      }),
      {},
    );
    data.raw = raw;

    return data;
  }, [settingsApiResponse, auth?.jwt]);

  if (tenantSettings !== undefined)
    return (
      <TenantContext.Provider value={tenantSettings}>
        {children}
      </TenantContext.Provider>
    );
  else return <LoadingSplash />;
};

const useTenantContext = () => {
  const ctx = useContext(TenantContext);
  return ctx;
};

export { TenantContext, TenantContextProvider, useTenantContext };
export type { TenantContextProperties };
