import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '../interfaces/redux/GlobalState';
import { useHistory } from 'react-router';
import { createLogoutAction } from '../redux/action/authActionFactory';
import { createLoginPath } from '../routing/appUrlGenerator';

export const SYSTEM_ADMIN = 'SystemAdmin';
export const TENANT_ADMIN = 'TenantAdmin';

export const CUSTOMER_USER = 'Customer';
export const FACTORY_USER = 'Factory';
export const OFFICE_USER = 'Office';
export const ADMIN_USER = 'admin';
export const VISIBLE_INVOICE = 'Invoice';

export function useAuth() {
    const auth = useSelector((state: GlobalState) => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const role = auth.user['custom:role'];
    const userRole = auth.userDbData?.userrole;
    const tier = auth.user['custom:tier'];

    function handleLogOut() {
        dispatch(createLogoutAction());
        //setTimeout(()=>location.replace(createLoginPath()), 1000);
    }

    function isSystemAdmin() {
        return role === SYSTEM_ADMIN;
    }

    function isTenantAdmin() {
        return role === TENANT_ADMIN;
    }

    function isCustomerUser() {
        return userRole === CUSTOMER_USER;
    }

    function isFactoryUser() {
        return userRole === FACTORY_USER;
    }

    function isOfficeUser() {
        return userRole === OFFICE_USER;
    }

    function isAdminUser() {
        return userRole === ADMIN_USER;
    }

    function isNotACustomer() {
        return isFactoryUser() || isOfficeUser() || isAdminUser();
    }

    function isVisibleFor(permissions: string[]): boolean {
        if (isAdminUser()) {
            return true;
        }

        return permissions.includes(userRole.toLowerCase()) || permissions.includes(userRole);
    }

    return {
        handleLogOut,
        role,
        tier,
        isSystemAdmin,
        isTenantAdmin,
        isCustomerUser,
        auth,
        isFactoryUser,
        isOfficeUser,
        isAdminUser,
        isNotACustomer,
        userDataIsLoading: auth.userDbData === null,
        userRole,
        isVisibleFor,
        currentUserName: auth.userDbData?.userName,
        tenantId: auth.userDbData?.tenantId,
        exp: auth.exp
    };
}
