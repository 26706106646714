import { Configuration } from '../api/shared/configuration';
import {
  Approval,
  DeliveryProposal,
  OrderApi,
  OrderDetails,
  OrderProgressSummary,
  OrderSummary,
  OrderSummaryStateEnum,
} from '../api/order/api';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '../interfaces/redux/GlobalState';
import {
  createSetDetailsAction,
  createSetFormValuesAction,
  createApproveOrderPartAction,
  createSetOrderStateAction,
  AssignUserToOrderParams,
  createSetDoneStateAction,
  createInUseByActiveUserAction,
  createAssignUsersToOrderAction,
  createSetExternalDocumentIdAction,
} from '../redux/action/orderActionFactory';
import { showNotification, showErrorNotification } from '../utils/notification';
import produce from 'immer';
import { useAuth } from './useAuth';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { NotificationContext } from '../context/NotificationContext';

export interface FormImage {
  id: string;
  url: string;
  s3key: string;
  order_id: string;
  composedOrderImageId: string;
  formId: string;
  mime_type: string;
  original_filename: string;
  createTimestamp?: number;
  thumbnail?: string;
  uploadedBy?:string;
}

export function useOrder() {
  const dispatch = useDispatch();
  const { auth, currentUserName } = useAuth();
  const order = useSelector((state: GlobalState) => state.order);
  const orderDetails = order.details;
  const apiOptions = useMemo(() => {
    return {
      basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
      accessToken: auth?.jwt,
    };
  }, [auth?.jwt]);

  const orderApi = new OrderApi(new Configuration(apiOptions));

  const { t } = useTranslation(['order']);
  const notificationContext = useContext(NotificationContext);

  async function getOrderById(id: string) {
    return await orderApi.getOrderById(id);
  }

  async function getOrder(id: string) {
    const response = await getOrderById(id);
    if (response) {
      dispatch(createSetDetailsAction(response.data));
    }

    return response;
  }

  async function getDeliveryProposalForToken(token: string) {
    if (token === undefined || token.trim() == '') return undefined;

    let proposal: DeliveryProposal | undefined = undefined;

    try {
      const proposalResponse = await orderApi.getProposalByHash(token.trim());
      if (proposalResponse.status === 200) {
        if (
          auth.user.customerId &&
          proposalResponse.data.customerId !== auth.user.customerId
        ) {
          location.replace('/');
          return;
        }
        proposal = proposalResponse.data;
      }
    } catch (e) {
      location.replace('/');
      
      // if (notificationContext) {
      //   notificationContext.showWarningNotification(
      //     'Failed to load proposal data due to error',
      //   );
      // } else {
      //   console.warn('Failed to load proposal data due to error');
      // }
      return undefined;
    }

    return proposal;
  }

  async function updateProposalAnswer(
    hashToken: string,
    proposalId: number,
    availableFullDay: boolean,
    availableMorning: boolean,
    availableAfternoon: boolean,
  ) {
    if (
      hashToken && proposalId
    ) {
      try {
        const timeframe : string|undefined = availableMorning === true ? "07:00 - 12:00" : availableAfternoon ? "12:00 - 17:00" : undefined;

        const response = await orderApi.processProposalAnswer(hashToken, {
            id: proposalId,
            proposalAccepted: (availableFullDay === true || availableMorning === true || availableAfternoon === true),
            proposalResponseTimeFrame: timeframe
        });

        if (response.status === 200) {
            if (response.data.success === true) {
                return true;
            } else {
                if (response.data.message) {
                    if (response.data.message === "E_ALREADY_ANSWERED") {
                        if (notificationContext) {
                            notificationContext.showWarningNotification("Proposal already acknowledged");
                        }
                    }
                }
                return false;
            }
        } else {
            return false;
        }
      } catch (e) {
        console.warn(e);
        return false;
      }
    }
    return false;
  }

  async function putOrder(
    id: string,
    formId: string,
    orderDetails: OrderDetails,
  ) {
    try {
      let orderResponse = await orderApi.updateOrder(id, formId, orderDetails);
      if (orderResponse.status === 201) {
        dispatch(
          createSetExternalDocumentIdAction({
            orderId: id,
            extDocumentId: orderResponse.data.extDocumentId,
          }),
        );
      }
      showNotification(t('order:submit-confirmation'));
    } catch (error) {
      showErrorNotification(`Something went wrong updating an order: ${error}`);
    }
  }

  function setFormValues(id: string, values: any) {
    dispatch(
      createSetFormValuesAction({
        id,
        values,
      }),
    );
  }

  async function approveOrderPart(
    orderId: string,
    formId: string,
    approval: Approval,
  ) {
    try {
      await orderApi.approveOrderPart(orderId, formId, approval);

      dispatch(
        createApproveOrderPartAction({
          orderId,
          formId,
          approval,
        }),
      );
    } catch (error) {
      showErrorNotification(
        `Something went wrong approving an order part: ${error}`,
      );
    }
  }

  async function updateOrderProgress(
    orderId: string,
    progressSummary: OrderProgressSummary[],
  ) {
    try {
      await orderApi.updateOrderProgress(orderId, progressSummary);

      for (const ps of progressSummary) {
        if (!ps.formId) return;
        const formId = ps.formId;

        dispatch(
          createSetDoneStateAction({
            orderId,
            formId,
            progressState: ps.progressState,
          }),
        );
      }

      showNotification('Order progress successfully updated');
    } catch (error) {
      showErrorNotification(
        `Something went wrong updating order progress: ${error}`,
      );
    }
  }

  async function handleOrderStateChange(
    orderId: string,
    state: OrderSummaryStateEnum,
  ) {
    const newOrderState = produce(order.details[orderId], (draft) => {
      if (!draft.summary) {
        return;
      }

      draft.summary.id = Number.parseInt(orderId);
      draft.summary.state = state;

      draft.summary = unmapNullValues(draft.summary);
    });

    await orderApi.updateOrderSummary(orderId, newOrderState.summary);
    dispatch(
      createSetOrderStateAction({
        orderId,
        state,
      }),
    );
  }

  function unmapNullValues<T>(values: T): T {
    let newValues = { ...values };
    let keys = Object.keys(values as any);

    keys.forEach((k) => {
      if (newValues[k] === null) {
        delete newValues[k];
      }
    });

    return newValues;
  }

  function orderProductIsDone(orderId: string, sequence: number) {
    const orderSummary = order.summary[orderId];

    if (!orderSummary || !orderSummary.progressData) {
      return;
    }
    return (
      orderSummary.progressData.filter(
        (data) =>
          data.formId !== 'transport' &&
          data.formId &&
          data.formId.split('::')[2] === `${sequence}` &&
          data.progressState !== 'done',
      ).length === 0
    );
  }

  function orderFormIsDone(orderId: string, formId: string) {
    const orderSummary = order.summary[orderId];

    if (!orderSummary || !orderSummary.progressData) {
      return;
    }

    return (
      orderSummary.progressData.filter(
        (data) => data.formId === formId && data.progressState === 'done',
      ).length > 0
    );
  }

  function orderPartIsApproved(orderId: string, formId: string) {
    const orderSummary = order.summary[orderId];

    if (!orderSummary || !orderSummary.progressData) {
      return;
    }

    return (
      orderSummary.progressData.filter(
        (data) => data.formId === formId && data.approvalState === 'approved',
      ).length > 0
    );
  }

  async function assignOrderToUser({
    userNames,
    orderId,
  }: AssignUserToOrderParams) {
    try {
      await orderApi.assignUserToOrder(orderId, {
        username: userNames,
      });

      dispatch(
        createAssignUsersToOrderAction({
          userNames,
          orderId,
        }),
      );
    } catch (error) {
      showErrorNotification(`Something went wrong assigning a user: ${error}`);
    }
  }

  async function getOrdersInUseByActiveUserDirect() {
    try {
      const response = await orderApi.getOrdersInUseByActiveUser();

      const workonfilter = (item: OrderSummary) => {
        let r =
          item.workedOnBy?.filter(
            (x) => x.indexOf(`[${currentUserName}]`) !== -1,
          ) ?? [];
        return r.length > 0;
      };

      // @ts-ignore -> result should not be a property in data
      let ids: string[] = response.data
        .filter((o) => o.id !== undefined && workonfilter(o))
        .map((order) => order.id);
      let orderIds = ids.filter((x, i, a) => a.indexOf(x) === i);

      return response.data.filter(
        (o) => o.id !== undefined && orderIds.includes(o.id.toString()),
      );
    } catch (error) {}
    return [];
  }

  async function getOrdersInUseByActiveUser() {
    try {
      const response = await orderApi.getOrdersInUseByActiveUser();

      const workonfilter = (item: OrderSummary) => {
        let r =
          item.workedOnBy?.filter(
            (x) => x.indexOf(`[${currentUserName}]`) !== -1,
          ) ?? [];
        return r.length > 0;
      };

      // @ts-ignore -> result should not be a property in data
      let ids: string[] = response.data
        .filter((o) => o.id !== undefined && workonfilter(o))
        .map((order) => order.id);
      let orderIds = ids.filter((x, i, a) => a.indexOf(x) === i);

      dispatch(createInUseByActiveUserAction(orderIds));
    } catch (error) {}
  }

  async function getOrdersInUseByUser(name: string, username: string) {
    try {
      const response = await orderApi.getOrdersInUseByUser(name);

      const workonfilter = (item: OrderSummary) => {
        let r =
          item.workedOnBy?.filter((x) => x.indexOf(`[${username}]`) !== -1) ??
          [];
        return r.length > 0;
      };

      // @ts-ignore -> result should not be a property in data
      let ids: string[] = response.data
        .filter((o) => o.id !== undefined && workonfilter(o))
        .map((order) => order.id);
      return ids.filter((x, i, a) => a.indexOf(x) === i);
    } catch (error) {}
  }

  return {
    orderApi,
    getOrderById,
    order,
    getOrder,
    putOrder,
    orderDetails,
    approveOrderPart,
    handleOrderStateChange,
    setFormValues,
    orderPartIsApproved,
    assignOrderToUser,
    getOrdersInUseByActiveUser,
    getOrdersInUseByUser,
    updateOrderProgress,
    orderProductIsDone,
    orderFormIsDone,
    getOrdersInUseByActiveUserDirect,
    getDeliveryProposalForToken,
    updateProposalAnswer
  };
}
