import { useMemo } from 'react';
import { Configuration } from '../api/shared/configuration';
import {
  CalendarEvent,
  OrderApi,
  OrderSummary,
  OrderTransport,
} from '../api/order/api';
import { useSelector } from 'react-redux';
import { GlobalState } from '../interfaces/redux/GlobalState';
import { CustomerSummary } from '../api/customer';

export interface Pin {
  customer: CustomerSummary;
  order: OrderSummary;
  transport: OrderTransport;
  deliveryDate: string | null;
}

export interface TransportPinWithCoords extends Pin {
  coords?: { lat: number; lon: number };
}

export function useTransport() {
  const auth = useSelector((state: GlobalState) => state.auth);
  const apiOptions = {
    basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
    accessToken: auth?.jwt,
  };
  const orderApi = useMemo(
    () => new OrderApi(new Configuration(apiOptions)),
    [auth?.jwt],
  );

  async function getTransport() {
    try {
      const response = await orderApi.getTransport();
      return response.data;
    } catch (error) {}
  }

  async function getDeliveryById(transportId: string) {
    try {
      const response = await orderApi.getDeliveryById(transportId);
      return response.data;
    } catch (error) {}
  }

  async function updateDelivery(calendarEvent: CalendarEvent) {
    try {
      const response = await orderApi.updateDelivery(calendarEvent);
      return response.data;
    } catch (error) {}
  }

  async function deleteDelivery(transportId: number) {
    try {
      const response = await orderApi.deleteDelivery(String(transportId));
      return response.data;
    } catch (error) {}
  }

  async function sendDeliveryNotification(date?: Date) {
    
    if (!date) return false;
    try {
      await orderApi.sendNotificationForDeliveryDate(date?.toISOString());
      return true;
    } catch (e) {
      return false;
    }
  }

  return {
    getTransport,
    getDeliveryById,
    updateDelivery,
    deleteDelivery,
    sendDeliveryNotification,
  };
}
