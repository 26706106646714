import Button from '../../../components/shared/controls/Button/Button';
import FormGroup from '../../../components/shared/controls/Formik/FormGroup';
import Island from '../../../components/shared/controls/Island/Island';
import LoadingSplash from '../../../components/shared/LoadingSplash';
import React, { FC, useState } from 'react';
import ReactTextArea from 'react-textarea-autosize';
import { Calendar } from 'primereact/calendar';
import {
  CalendarEvent,
  GetDeliveryById200Response,
  OrderSummary,
  OrderTransport,
  OrderTransportByEnum,
  OrderTransportSameAddressEnum,
} from '../../../api/order';
import { Configuration } from '../../../api/shared/configuration';
import {
  createHomePath,
  createOrdersDeliveryPath,
} from '../../../routing/appUrlGenerator';
import { CustomerApi, CustomerDetails } from '../../../api/customer';
import { Dropdown } from 'primereact/dropdown';
import { Field, FieldProps, Formik } from 'formik';
import { object, string } from 'yup';
import { RouteConfigComponentProps } from 'react-router-config';
import { showNotification } from '../../../utils/notification';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router';
import { useOnMount } from '../../../hooks/useUpdate';
import { useOrder } from '../../../hooks/useOrder';
import { useTranslation } from 'react-i18next';
import { useTransport } from '../../../hooks/useTransport';
import { useTenantContext } from '../../../context/TenantContext';

const CreateDeliveryPage: FC<RouteConfigComponentProps<any>> = ({ match }) => {
  const [event, setEvent] = useState<CalendarEvent>();
  const [calendars, setCalendars] = useState<any>();
  const [reschedule, setReschedule] = useState<boolean>(false);

  const { t } = useTranslation(['delivery', 'notifications']);
  const { getOrderById } = useOrder();
  const { getDeliveryById, updateDelivery, deleteDelivery } = useTransport();
  const tenantContext = useTenantContext();

  const { auth } = useAuth();
  const { isOfficeUser, isAdminUser } = useAuth();
  const apiOptions = new Configuration({
    basePath: process.env.REACT_APP_SERVICE_URL_CUSTOMER,
    accessToken: auth?.jwt,
  });
  const customerApi = new CustomerApi(apiOptions);
  const history = useHistory();
  let transportId = match.params.id;

  const fetchTransportById = async () => {
    if (!transportId) return;

    let event = await getDeliveryById(transportId);
    setCalendars(event?.calendars);
    if (event?.event) {
      setReschedule(true);

      if (event.event.deliveryDate !== event.transport?.deliveryDate) {
        event.event.deliveryDate =
          event.transport?.deliveryDate ?? new Date().toISOString();
      }

      setEvent(event.event);
    } else {
      if (!isOfficeUser() && !isAdminUser()) {
        history.push(createHomePath());
        return;
      }
      if (event?.transport) {
        setReschedule(false);
        constructInitialValues(event);
      }
    }
  };

  const constructInitialValues = async (event: GetDeliveryById200Response) => {
    const transport = event.transport;
    if (!transport?.orderId) return;
    let orderId = transport.orderId.toString();
    const response = await getOrderById(orderId);
    if (!response.data.summary?.customerId) {
      return;
    }
    const summary = response.data.summary;
    let customerId = response.data.summary.customerId;
    let customerResponse = await customerApi.getCustomerById(customerId);
    if (!customerResponse?.data) return;
    let customer: CustomerDetails = customerResponse.data;

    let defaultCalendar: string | undefined;
    let calendarId: string | undefined;

    calendarId = event.calendars ? event.calendars[0].value : '';

    if (
      tenantContext &&
      tenantContext.raw &&
      'GCAL:DEFAULT-CALENDAR' in tenantContext.raw
    ) {
      defaultCalendar = String(tenantContext.raw['GCAL:DEFAULT-CALENDAR']);

      if (event.calendars?.find((d) => d.value === defaultCalendar))
        calendarId = defaultCalendar;
    }

    setEvent({
      summary: `${
        transport.by === OrderTransportByEnum.PickUp
          ? t('type.pickup')
          : t('type.deliver')
      }${customer.businessName ? ` ${customer.businessName}` : ''} ${
        customer.firstName
      } ${customer.lastName}`,
      calendarId: calendarId,
      description: getDescription(summary, transport),
      location:
        transport.sameAddress === OrderTransportSameAddressEnum.No
          ? `${transport.street} ${transport.postalcode} ${transport.city}`
          : `${customer.street} ${customer.number}${
              customer.numberSuffix ? ` ${customer.numberSuffix}` : ''
            } ${customer.postalcode} ${customer.city}`,
      deliveryDate: new Date().toISOString(),
      orderId: parseInt(orderId),
    });
  };

  const getDescription = (summary: OrderSummary, transport: OrderTransport) => {
    let description = `${t('open-balance')}: ${
      summary.orderTotal !== undefined && summary.paid !== undefined
        ? summary.orderTotal - summary.paid
        : ''
    }`;
    if (transport.internalComment)
      description += `, ${t('internal-remark')}: ${transport.internalComment}`;

    return description;
  };

  useOnMount(() => {
    fetchTransportById();
  }, []);

  const handleRemoveDelivery = async (transportId: string) => {
    await deleteDelivery(Number(transportId));
    showNotification(t('notifications:delivery-event-succesfully-removed'));
    history.go(-1);
  };

  const handleSubmit = async () => {
    await updateDelivery({
      ...event,
      transportId: Number.parseInt(transportId),
    });
    showNotification(t('notifications:delivery-event-succesfully-updated'));
    history.go(-1);
    //history.push(createOrdersDeliveryPath());
  };

  if (!event) {
    return <LoadingSplash />;
  }

  return (
    <Island
      className="card"
      title={t('detailsTitle')}
      onBackClick={
        () => history.go(-1) /*history.push(createOrdersDeliveryPath())*/
      }
    >
      {event && event.deliveryDate && (
        <Formik
          initialValues={event}
          onSubmit={handleSubmit}
          validationSchema={object().shape({
            summary: string().required(
              t('common:validation.fieldrequired', {
                replace: { field: t('summary') },
              }),
            ),
            location: string().required(
              t('common:validation.fieldrequired', {
                replace: { field: t('location') },
              }),
            ),
            description: string().required(
              t('common:validation.fieldrequired', {
                replace: { field: t('description') },
              }),
            ),
            deliveryDate: string().required(
              t('common:validation.fieldrequired', {
                replace: { field: t('deliveryDate') },
              }),
            ),
          })}
          enableReinitialize
        >
          {({ handleSubmit, isSubmitting }) =>
            event.deliveryDate !== undefined && (
              <form onSubmit={handleSubmit}>
                <FormGroup name="summary" label={t('summary')}>
                  <Field
                    disabled={!isOfficeUser() && !isAdminUser()}
                    name="summary"
                    className="p-inputtext p-component"
                  />
                </FormGroup>

                <FormGroup name="calendar" label={t('calendar')}>
                  <Dropdown
                    id="calendar"
                    disabled={!isOfficeUser() && !isAdminUser()}
                    options={calendars}
                    value={event.calendarId}
                    onChange={(e: any) =>
                      setEvent({
                        ...event,
                        calendarId: e.value,
                      })
                    }
                  />
                </FormGroup>

                <FormGroup name="location" label={t('location')}>
                  <Field
                    disabled={!isOfficeUser() && !isAdminUser()}
                    name="location"
                    className="p-inputtext p-component"
                  />
                </FormGroup>

                <FormGroup name="description" label={t('description')}>
                  <Field name={'description'} placeholder={'typ hier text'}>
                    {({ field }: FieldProps) => (
                      <ReactTextArea
                        disabled={!isOfficeUser() && !isAdminUser()}
                        className="p-component p-inputtext"
                        minRows={4}
                        {...field}
                      />
                    )}
                  </Field>
                </FormGroup>

                <FormGroup name="deliveryDate" label={t('deliveryDate')}>
                  <div className="flex gap-2">
                    <Calendar
                      className="w-8 md:w-2"
                      disabled={!isOfficeUser() && !isAdminUser()}
                      name="deliveryDate"
                      value={new Date(event.deliveryDate)}
                      onChange={(e) => {
                        if (e.value) {
                          setEvent((ev) => {
                            return {
                              ...ev,
                              deliveryDate: (e.value as Date).toISOString(),
                            };
                          });
                        }
                      }}
                      dateFormat="dd-mm-yy"
                    />
                    <Calendar
                      className="w-4 md:w-1"
                      disabled={!isOfficeUser() && !isAdminUser()}
                      timeOnly
                      name="deliveryDateTime"
                      value={new Date(event.deliveryDate)}
                      onChange={(e) => {
                        if (e.value) {
                          setEvent((ev) => {
                            return {
                              ...ev,
                              deliveryDate: (e.value as Date).toISOString(),
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </FormGroup>
                {(isOfficeUser() || isAdminUser()) && (
                  <div className="flex gap-3">
                    <Button buttonType="submit" disabled={isSubmitting}>
                      {t(
                        `common:actions.${event.extEventId ? 'edit' : 'save'}`,
                      )}
                    </Button>
                    <Button
                      buttonType="button"
                      className="button-danger"
                      disabled={!reschedule}
                      onClick={() => handleRemoveDelivery(transportId)}
                    >
                      {t('common:actions.delete')}
                    </Button>
                  </div>
                )}
              </form>
            )
          }
        </Formik>
      )}
    </Island>
  );
};

export default CreateDeliveryPage;
